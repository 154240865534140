import { FC } from "react";
import joinClassNames from "classnames";

import Icon from "./Icon";
import { BaseProps, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends BaseProps {
  className?: string;
  size?: Sizes;
}

const CoinIcon: FC<Props> = ({
  className,
  src: icon,
  size = "sm",
  altImage,
}) => (
  <Icon
    src={icon}
    data-size={size}
    altImage={altImage}
    className={joinClassNames(
      classes.sizePresets,
      classes.coinIcon,
      className,
    )}
  />
);

export default CoinIcon;
